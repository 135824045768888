
import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { jsx } from "theme-ui"

const Header = ({ siteTitle, menuLinks }) => (
  
  <header>
    <div class="header">
      <a href="#modal-opened" id="modal-closed" class="logo"><img alt="PetNeck2 Logo" src="https://petneck2-strapi.s3.eu-west-2.amazonaws.com/petneck2_logo_2_63db408969.png"/></a>
      <input class="menu-btn" type="checkbox" id="menu-btn" />
      <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
      <ul class="menu">  
        {menuLinks.map((link) => (
          <li key={link.name} >
            <a href={link.link} aria-haspopup={link.subMenu && link.subMenu.length > 0 ? true : false}> {link.name} </a>
            {link.subMenu && link.subMenu.length > 0 ? (
              <ul aria-label="submenu">
                {link.subMenu.map((subLink) => (
                  <li key={subLink.name}>
                    <a href={subLink.link}> {subLink.name}</a>
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  </header>
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header




