/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Header from "./header"

import "./layout.css"
import "./header.css"
import "./footer.css"
import "./content.css"
import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import ReactMarkdown from "react-markdown";

const Layout = ({ children }) => {
const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        menuLinks {
          subMenu {
            link
            name
          }
          name
          link
        }
      }
    }
    allStrapiSocial {
      nodes {
        Facebook
        Instagram
        LinkedIn
        Pinterest
        Twitter
      }
    }
    allStrapiFooter {
      nodes {
        Footer_1
        Footer_2
        Footer_3
        Footer_1_Title
        Footer_2_Title
        Footer_3_Title
      }
    }
  }
`)


  return (
    <>

      <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title || `Title`} />
      <div style={{
                    margin: `0 auto`,
                    maxWidth: 1980,

                  }}
      >

        <main>{children}</main>

      </div>

      <footer>
      <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
      </svg>
       
        <div class="row">
          <div class="social">
          {/*
            {data.allStrapiSocial.nodes.map(contentblock => (
              <div>
                <a href={contentblock.Facebook} aria-label="petneck2 facebook page">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a href={contentblock.Twitter} aria-label="petneck2 twitter feed">
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
                <a href={contentblock.LinkedIn} aria-label="petneck2 linkedIn profile">
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
                <a href={contentblock.Instagram} aria-label="petneck2 instagram feed">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>                
              </div>
            ))}*/}
          </div>
        </div>
        <div class="row no_padding">
          <div class ="links">
            <a href="/privacy"> Privacy</a>
            <a href="/accessibility"> Accessibility</a>
          </div>
        </div>
        <div class="row">
          <div class ="copyright">
            © Copyright {new Date().getFullYear()}
            <a href="https://endoscope-i.com"> Endoscope-i</a>
            {` and the PETNECK2 team | All Rights Reserved`} 
          </div>
          
        </div>
      </footer>
    </>
  )
}

/*
{data.allStrapiFooter.nodes.map(contentblock => (
          <div class="footer-content">
            <div class="row">
              <div class="column">
                <h4>{contentblock.Footer_1_Title}</h4>
                <p><ReactMarkdown>{contentblock.Footer_1}</ReactMarkdown></p>
              </div>
              <div class="column">
                <h4>{contentblock.Footer_2_Title}</h4>
                <p><ReactMarkdown>{contentblock.Footer_2}</ReactMarkdown></p>
              </div>
              <div class="column">
                <h4>{contentblock.Footer_3_Title}</h4>
                <p><ReactMarkdown>{contentblock.Footer_3}</ReactMarkdown></p>
              </div>
            </div>
          </div>
        ))} 

        */

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

